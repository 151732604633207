<template>
	<el-dialog 
		title="货车轨迹" 
		top="1vh" width="90%" 
		:visible.sync="is_show_in_page" 
		@close="close"
	>	
	<div style="margin-bottom:10px;" v-if="class_hide">
		 <el-button @click="get_point_list('get_path_data_daka')"  plain>app打卡轨迹</el-button>
		 <el-button @click="get_point_list('get_path_data_zj')" plain>中交轨迹</el-button>
		 <!-- <el-button @click="get_point_list('get_path_data_gd')"  plain>高德导航</el-button>
		 <el-button @click="get_point_list('get_path_data_ts')"  plain>客户推送轨迹</el-button> -->
	</div>
	<div class="head-head">
		<span>运单编号：{{truck_tord_num}}</span>
		<span>车牌号：{{truck_plate_num}}</span>
		<span>发车时间：{{start_time_text}}</span>
		<span>到货时间：{{end_time_text}}</span>
	</div>
	<div v-show="show">无轨迹</div>
		<div v-show="!show" id="container" style="width:100%" :style="{height:map_height+'px'}"></div>
	</el-dialog>
</template>

<script>
import {mapState} from 'vuex'
	export default {
		props:{
			case_county:String,
			aim_county:String,
			start_time:Number,
			end_time:Number,
			truck_plate_num:String,
			ctr:String,
			driver_id:String,
			truck_tord_num:String
		},
		data() {
			return {
				//可显示轨迹按钮的手机号
				is_show_tel:[
				'13783981152','18855370372','18056918989'
				],
				// '13223579790','13333574703','13453497870','19190503399','13834349811',
				class_hide:false,//是否显示预付金额
				marker_list:[],
				map_height:0,
				is_show_in_page:false,
				show:false,
				start_time_text:'',
				end_time_text:'',
			};
		},
		computed:{
			...mapState(['user_info'])
		},
		mounted() {
			if(this.is_show_tel.indexOf(this.user_info.tel)!=-1){
				this.class_hide =true
			}
			//发货地县区
			if(!this.case_county || !this.case_county.trim()){
				this.$my.other.msg({
					type:'warning',
					str:'缺少发货地县区'
				});
				this.$emit('close')
				return;
			}

			//到货地县区
			if(!this.aim_county || !this.aim_county.trim()){
				this.$my.other.msg({
					type:'warning',
					str:'缺少到货地县区'
				});
				this.$emit('close')
				return;
			}

			//车牌号
			// if(!this.$my.check.is_plate_num(this.truck_plate_num)){
			// 	this.$my.other.msg({
			// 		type:'warning',
			// 		str:'缺少车牌号'
			// 	});
			// 	this.$emit('close')
			// 	return;
			// }

			//开始时间
			if(!this.$my.check.is_num(this.start_time) || this.start_time<=0){
				this.$my.other.msg({
					type:'warning',
					str:'轨迹开始时间必须是数字'
				});
				this.$emit('close')
				return;
			}
			
			//结束时间
			if(!this.$my.check.is_num(this.end_time) || this.end_time<=0){
				this.$my.other.msg({
					type:'warning',
					str:'轨迹结束时间必须是数字'
				});
				this.$emit('close')
				return;
			}

			//车牌号
			if(!this.$my.check.is_plate_num(this.truck_plate_num)){
				this.$my.other.msg({
					type:'warning',
					str:'缺少车牌号'
				});
				this.$emit('close')
				return;
			}
			this.start_time_text =this.$my.other.totime(this.start_time);
			this.end_time_text =this.$my.other.totime(this.end_time);
			//打开弹出层
			this.is_show_in_page=true;

			//地图高度
			this.map_height=document.documentElement.clientHeight*0.8;
			window.onresize = () => {
				this.map_height=document.documentElement.clientHeight*0.8;
			}

			//加载地图
			this.$amap.load({
				plugins:[
					'AMap.DistrictSearch',
				]
			}).then((AMap)=>{

				//
				this.AMap=AMap;

				//地图初始化
				this.map=new AMap.Map("container",{

					resizeEnable: true,

					//初始化地图级别
					zoom:10,
				});

				//发到货地区划
				this.show_district({
					case_county:this.case_county,
					aim_county:this.aim_county,
				});

				//读取轨迹


				this.get_point_list('get_path_data_daka');
			})
		},
		methods: {
			init(type){
				//地图高度
				this.map_height=document.documentElement.clientHeight*0.8;
				window.onresize = () => {
					this.map_height=document.documentElement.clientHeight*0.8;
				}

				//加载地图
				this.$amap.load({
					plugins:[
						'AMap.DistrictSearch',
					]
				}).then((AMap)=>{

					//
					this.AMap=AMap;

					//地图初始化
					this.map=new AMap.Map("container",{

						resizeEnable: true,

						//初始化地图级别
						zoom:10,
					});

					//发到货地区划
					this.show_district({
						case_county:this.case_county,
						aim_county:this.aim_county,
					});
					if(type !='null'){
						this.get_point_list('get_path_data_daka')
					}
				})
			},
			init1(){
				//地图高度
				this.map_height=document.documentElement.clientHeight*0.8;
				window.onresize = () => {
					this.map_height=document.documentElement.clientHeight*0.8;
				}

				//加载地图
				this.$amap.load({
					plugins:[
						'AMap.DistrictSearch',
					]
				}).then((AMap)=>{

					//
					this.AMap=AMap;

					//地图初始化
					this.map=new AMap.Map("container",{

						resizeEnable: true,

						//初始化地图级别
						zoom:10,
					});

					//发到货地区划
					this.show_district({
						case_county:this.case_county,
						aim_county:this.aim_county,
					});
				})
			},
			//发到货地区划
			show_district(para){

				//取出地图对象
				let AMap = this.AMap;

				//实例化搜索对象
				let ser_obj=new AMap.DistrictSearch({
					level:'district',
					extensions:'all',
					subdistrict:0,
				});

				//搜索发货地
				ser_obj.search(para.case_county,(status,result)=>{

					if(status!='complete'){
						this.$my.other.msg({
							type:'warning',
							str:'搜索发货地失败'
						});
						return;
					}

					//显示区划
					let bounds=result.districtList[0].boundaries
					if(bounds){

						var polygons=[]
						for(var i=0;i<bounds.length;i++){

							//生成行政区划polygon
							var polygon=new AMap.Polygon({
								map: this.map,
								strokeWeight: 1,
								path: bounds[i],
								fillOpacity: .2,
								fillColor: '#CCF3FF',
								strokeColor: '#CC66CC'
							})
							polygons.push(polygon)
						}

						// 地图自适应
						this.map.setFitView()
					}
				})

				//搜索到货地
				ser_obj.search(para.aim_county,(status,result)=>{

					if(status!='complete'){
						this.$my.other.msg({
							type:'warning',
							str:'搜索到货地失败'
						});
						return;
					}

					//显示区划
					let bounds = ''
					result.districtList.forEach(item=>{
						if(item.name == para.aim_county){
							bounds = item.boundaries
						}
					})
					if(bounds){

						var polygons=[]
						for(var i=0;i<bounds.length;i++){

							//生成行政区划polygon
							var polygon=new AMap.Polygon({
								map: this.map,
								strokeWeight: 1,
								path: bounds[i],
								fillOpacity: .2,
								fillColor: '#CCF3FF',
								strokeColor: '#CC66CC'
							})
							polygons.push(polygon)
						}

						// 地图自适应
						this.map.setFitView()
					}
				})
			},

			//读取轨迹
			get_point_list(ctr){
				this.show = false
				let that = this
				this.init1()
				//调接口
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'res',
						ctr,
						// truck_plate_num:para.truck_plate_num,
						// start_time:para.start_time,
						// end_time:para.end_time,
						// driver_id:this.driver_id,
						truck_tord_num:this.truck_tord_num,
						system_type:3,
					},
					callback:(data)=>{
					if(data.code != 0){
						this.show = true
						this.$my.other.msg({
							type:'warning',
							str:data.error_info
						});
						this.init('null')
					}else{
						if(data.msg.list.length==0){
							this.$my.other.msg({
								type:'warning',
								str:'未读取到轨迹'
							});
							this.init('null')
							return;
						}

						//取出地图对象
						let AMap = this.AMap;

						//地图中间点
						let map_center=data.msg.list[parseInt(data.msg.list.length/2)]
						if(map_center){
							this.map.setCenter(new AMap.LngLat(map_center.lon,map_center.lat)); 
						}
						
						//标记列表
						let marker_list=[];

						//设置开始点
						let first_point=data.msg.list[0];
						console.log(first_point.timestamp)
						marker_list.push(new AMap.Marker({
							position:new AMap.LngLat(first_point.lon,first_point.lat),
							label:{
								content:`起点 ${that.$my.other.totime(first_point.timestamp)}`, //设置文本标注内容
								direction: 'right' //设置文本标注方位
							},
						}))

						//设置终点
						let last_point=data.msg.list[data.msg.list.length-1];
						marker_list.push(new AMap.Marker({
							position:new AMap.LngLat(last_point.lon,last_point.lat),
							label:{
								content:`终点 ${that.$my.other.totime(last_point.timestamp)}`, //设置文本标注内容
								direction:'right' //设置文本标注方位
							},
						}))

						//线列表
						let path=[];
						let every_length=parseInt(data.msg.list.length/10)
						for(var i=0;i<data.msg.list.length;i++){

							//置入线数据
							path.push(new AMap.LngLat(data.msg.list[i].lon,data.msg.list[i].lat));

							//中间点
							if(i%every_length==0){
								var center_point=data.msg.list[i];
								that.marker_list.push(data.msg.list[i])
								marker_list.push(new AMap.Marker({
									position:new AMap.LngLat(center_point.lon,center_point.lat),
									label:{
										content:that.$my.other.totime(center_point.timestamp), //设置文本标注内容
										direction:'top' //设置文本标注方位
									},
								}));
							}
						}
						//置入点
						this.map.add(marker_list);

						//创建并置入线
						this.map.add(new AMap.Polyline({
							path: path,
							borderWeight:2,
							strokeColor: "#47aef3",
							strokeOpacity: 1,
							strokeWeight: 7,
							lineJoin: 'round',
							lineCap: 'round',
							showDir: true,
						}));
					}
					}
				});
			},

			//关闭
			close(){
				window.onresize=null;
				this.$emit('close')
			}
		},
	};
</script>
<style lang="scss" scoped>
 .head-head{
	 margin: 10px 0px;
	 span{
		 margin-right: 10px;
		
	 }
 }
</style>